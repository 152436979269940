import React from 'react'
import { IoPlayCircleOutline } from 'react-icons/io5'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink } from 'react-router-dom'

const InfoVideoCard = ({url, imageUrl, title}) => {
  return (
    <div >
    
        <NavLink to={`${url}`} target='_blank' >
        <div className='relative  bg-bgDark bg-opacity-80 rounded-lg overflow-hidden pointer-events-auto'>
        <div className='items-center flex justify-center '>
            <LazyLoadImage className='h-[300px] md:h-[400px] w-[300px] md:w-[400px] rounded-lg' src={imageUrl} loading='lazy' alt='img'></LazyLoadImage>
          </div>
            <div className='absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            {
              url ? <IoPlayCircleOutline size={100} /> : <div className='bg-white rounded-lg'><div className='text-3xl text-red-800 font-semidold p-4'>Recipe will be uploaded soon !!!</div> </div>
            }
                   
              </div>
            </div>
        </NavLink>
        
    </div>
  )
}

export default InfoVideoCard