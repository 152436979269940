import React, { useState,useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import ShimmerUIBlog from './ShimmerUIBlog';
import InfoCard from './Blogs/InfoCard';
import InfoPage from './Blogs/InfoPage';
import YoutubeEmbed from './YoutubeEmbed';
import "./YoutubeEmbed.css";
import Spinner from './Spinner';

function InfoBlogs() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    const foodVideosCollectionRef = collection(db, "InfoBlogs");

    const getData =  async() => {
        try{
            setLoading(true);

            const data = await getDocs(foodVideosCollectionRef);
            const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
       
            const data1 = filteredData.filter(x => x.isPublish === true);
            setPosts(data1);

            setLoading(false);
        }
        catch(er){
            console.log(er);
        }
      }

    useEffect(() => {
        getData();
        window.scroll(0,0);
      }, []);
    
  return (
    <div className=''>
        {/* <p className='text-md font-light py-3 px-5 '><strong className='font-bold text-3xl leading-5 text-richblack-700 uppercase text-red-700 font-mono'>Parenting Blog </strong> </p> */}
        {
            loading ? (<Spinner/>) : (
                <div>
                <InfoPage posts={posts}/>
                <div className='w-full md:w-10/12 mx-auto text-3xl text-blue-600 p-2'>You may also like</div>
                <div className='flex justify-evenly py-4 max-md:hidden'>
                    <iframe width="360" height="315" className='rounded-lg'
                    src="https://www.youtube.com/embed/ck8ZZ7f61fQ" 
                    title="YouTube video player" 
                    ></iframe>
                    <iframe width="360" height="315" className='rounded-lg'
                    src="https://www.youtube.com/embed/1h-Buyvan-M?autoplay=1&mute=1" 
                    title="YouTube video player" 
                    ></iframe>
                    <iframe width="360" height="315" className='rounded-lg'
                    src="https://www.youtube.com/embed/T51chIH2NLI" 
                    title="YouTube video player" 
                    ></iframe>
                </div>
                    <div className='flex flex-col gap-y-4 p-2 md:hidden'>
                        <YoutubeEmbed embedId='ck8ZZ7f61fQ' />
                        <YoutubeEmbed embedId='1h-Buyvan-M?autoplay=1&mute=1' />
                        <YoutubeEmbed embedId='T51chIH2NLI' />
                    </div>
                </div>
               
            )
        }
        
    </div>
  )
}

export default InfoBlogs