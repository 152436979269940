import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { IoMenuSharp } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import { db, storage } from '../../config/firebase';
import { NavLink, useNavigate } from 'react-router-dom';

import ImageUpload from './ImageUpload';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';

import Spinner from '../Spinner';
import JoditEditor from 'jodit-react';

const InsertInfoBlog = () => {

    const [imageUrl, setImageUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [title, setTitle] = useState('');
    const [avatarURL, setAvatarURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(true);
    const [docId, setDocId] = useState("");
    const [instaUrl, setInstaUrl] = useState('');

    const [posts, setPosts] = useState([]);
    const [uploadedBlogs, setUploadedBlogs] =  useState([]);

    const editor = useRef(null);
    const [description, setDescription] = useState("");

    const navigate = useNavigate();


    const handleDelete = async(id) => {
        var res = window.confirm("Delete the item?");
        if (res) {
        const foodDoc = doc(db, "InfoBlogs", id);
        await deleteDoc(foodDoc);

        getData();
    } else {
      // Do nothing!
      console.log('Not deleted');
    }
    }

    const handlePublish = async(id) => {
        const foodDoc = doc(db, "InfoBlogs", id);
        await updateDoc(foodDoc, {isPublish: true});

        alert('Blog Published Successfully !!!');

        getData();
    }


      const infoBlogsCollectionRef = collection(db, "InfoBlogs");

    const date = new Date();
    const showTime = date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

    const handleSubmit = async (e) => {
        try{
            e.preventDefault();

            if(selectedFile === undefined){
                alert('Upload image is missing !!!');
                return;
            }

            setLoading(true);

            const imageRef = ref(storage, `images/category/${showTime}-${selectedFile.name}`);
            await uploadBytes(imageRef, selectedFile);

            const imageUrl1 = await getDownloadURL(imageRef);
            setImageUrl(imageUrl1);

            await addDoc(infoBlogsCollectionRef, {
                title : title, 
                description: description,
                imageUrl : imageUrl1,
                instaUrl : instaUrl,
                isPublish : false
              });
            
              setLoading(false);
              clearData();
              
              navigate(`/infoblogpreview/${title}`);
        }
        catch(err){
            console.log(err);
        }
    }

    const handleEdit = (p) =>{
        setTitle(p.title);
        setDescription(p.description);
        setAvatarURL(p.imageUrl);
        setImageUrl(p.imageUrl);
        setIsSubmit(false);
        setDocId(p.id);
        setInstaUrl(p.instaUrl);

        window.scroll(0,0);
    }

    const getData =  async() => {
        const data = await getDocs(infoBlogsCollectionRef);
        const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
       
        const uploaded = filteredData.filter(x => x.isPublish === true);
        setUploadedBlogs(uploaded);
    
        setPosts(filteredData);
      }

     const handleUpdate = async() => {
        try
        {
            setLoading(true);
            const infoDoc = doc(db, "InfoBlogs", docId);

            if(!selectedFile){
                await updateDoc(infoDoc, {title : title, description: description, imageUrl: imageUrl, instaUrl : instaUrl, isPublish : false });
            }
            else{

                const imageRef = ref(storage, `images/category/${showTime}-${selectedFile.name}`);
                await uploadBytes(imageRef, selectedFile);

                const imageUrl1 = await getDownloadURL(imageRef);
                setImageUrl(imageUrl1);

                await updateDoc(infoDoc, 
                    {
                        title : title,
                        description : description,
                        imageUrl : imageUrl1,
                        instaUrl : instaUrl,
                        isPublish : false
                });
            }

            //alert("Updated Successfully !!!");
            navigate(`/infoblogpreview/${title}`);
            getData();
            clearData();

            setLoading(false);
        }
        catch(e)
        {
            console.log(e);
        }
    }

    const clearData = () => {
        setTitle('');
        setAvatarURL('');
    }

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        if(auth !== "Logged In")
        {
        navigate("/admin/login");
        }
        getData();
    }, []);

  return (
    <div>

    <div className='flex-col justify-between w-full bg-white mx-auto shadow-sm  py-4'>
        <div className='flex justify-between px-4'>
         
                <div className='font-semibold text-2xl text-center w-full'>Add Info Blog</div>
            
        </div>
        <div className=' bg-white m-20 p-7 rounded-md'>
        {
            loading && <div className=' flex items-center justify-center mt-40'><Spinner/></div>
        }
            <div className='flex-col justify-center'>
                <div className='text-xl font-semibold'>Add Information Blog</div>
                <div className='text-gray-600'>Easily input essential details like title,image and description etc.</div>
                <div className='mt-4 text-lg py-1'>Title Image <sup className='text-red-500 text-lg'>*</sup></div>
                <div className='border-2 w-[150px] h-[150px] rounded-md cursor-pointer'>
                
                <ImageUpload setSelectedFile={setSelectedFile} avatarURL={avatarURL} setAvatarURL={setAvatarURL}/>

                </div>
                <div className='flex justify-between gap-x-14'>

                    <div className='w-full mx-auto'>
                <div className='mt-4 text-lg py-1'>Title <sup className='text-red-500 text-lg'>*</sup></div>
                    <input
                        className='border-2 rounded-md py-3 w-full px-2 text-lg'
                        required
                        name='name'
                        placeholder='Enter title (What is positive parenting)'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}  />
                    </div>
                    </div>

                    <div className='flex justify-between gap-x-14'>

                    <div className='w-full mx-auto'>
                <div className='mt-4 text-lg py-1'>Instagram Link <sup className='text-red-500 text-lg'>*</sup></div>
                    <input
                        className='border-2 rounded-md py-3 w-full px-2 text-lg'
                        required
                        name='insta'
                        placeholder='Enter instagram url'
                        value={instaUrl}
                        onChange={(e) => setInstaUrl(e.target.value)}  />
                    </div>
                    </div>
                    
                    <div className='py-6'>
        
                    <div className=''>
                        <JoditEditor
                            ref={editor}
                            value={description}
                            onChange={newContent => setDescription(newContent)}              
                        />
                    </div>
               </div>
 
                <div className='text-center md:text-left py-4'>
                {
                    isSubmit ? (<button onClick={handleSubmit} className='bg-[#146eb4] text-white h-10 w-[200px] font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Save & Preview</button>) 
                            : (<button onClick={handleUpdate} className='bg-yellow-500 h-10 w-[200px] font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Update & Preview</button>)
                }
            
        </div>
                
            </div>
        </div>
    </div>
    <div className=' w-full mx-auto py-2'>
            
            
            <div className="overflow-hidden mt-10">
   <table className="w-10/12 mx-auto overflow-y-scroll text-left text-sm font-light">
            
            <thead className="font-medium ">
                <tr className='bg-orange-300 border-2 border-black text-blue-800 font-bold text-md md:text-xl '>
                <th scope="col" className="px-1 py-2 border-r-2 text-center">#</th>
                <th scope="col" className="px-1 py-2 border-r-2 text-center">Title</th>
                <th scope="col" className="px-1 py-2  text-center border-r-2" >Edit</th>
                <th scope="col" className="px-1 py-2 border-r-2 text-center" >Delete</th>
                <th scope="col" className="px-1 py-2 border-r-2 border-black text-center text-wrap" >Publish</th>
                </tr>
            </thead>

                <tbody>
            {
                
                posts.map((p, index) => (
                    
                    <tr className="border-2 border-black ">
                        <td className="whitespace-wrap text-[12px] font-medium px-1 py-2 border-r-2  align-baseline text-center text-wrap">{index+1}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center text-wrap">{p.title}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center">
                        <button onClick={() => handleEdit(p)} className='bg-blue-600 px-4 py-1 rounded-lg text-white'>Edit</button></td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center border-black">
                        <button onClick={() => handleDelete(p.id)} className='bg-red-600 px-4 py-1 rounded-lg text-white'>Delete</button>
                        </td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center border-black">
                        <button onClick={() => handlePublish(p.id)} className='bg-blue-600 px-4 py-1 rounded-lg text-white'>Publish</button>
                        </td>
                    </tr>)
        )     
       } 
       </tbody> 
        
       </table>
   </div> 
     
    </div>

    <hr className='my-4'/>
    <div className='p-4 font-bold text-2xl text-center'>Uploaded Blogs:</div>
    <div className=' w-full py-2'>
            
            
            <div className="overflow-hidden mt-10">
   <table className="w-10/12 mx-auto overflow-y-scroll text-left text-sm font-light">
            
            <thead className="font-medium ">
                <tr className='bg-orange-300 border-2 border-black text-blue-800 font-bold text-md md:text-xl '>
                <th scope="col" className="px-1 py-2 border-r-2 text-center">#</th>
                <th scope="col" className="px-1 py-2 border-r-2 border-black text-center">Title</th>
                </tr>
            </thead>

                <tbody>
            {
                
                uploadedBlogs.map((p, index) => (
                    
                    <tr className="border-2 border-black ">
                        <td className="whitespace-wrap text-[12px] font-medium px-1 py-2 border-r-2  align-baseline text-center text-wrap">{index+1}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 border-black align-baseline text-center text-wrap">{p.title}</td>
                    </tr>)
        )     
       } 
       </tbody> 
        
       </table>
   </div> 
     
    </div>
    </div>
  )
}

export default InsertInfoBlog