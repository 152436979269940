import React from 'react'
import { NavLink } from 'react-router-dom'

function FoodChartCard({post}) {
  return (
    <div className='relative w-[280px] bg-bgDark bg-opacity-80 rounded-lg overflow-hidden shadow-lg pointer-events-auto '>
    <NavLink to={`/babyfoodcharts/${post.url}`}>
        
      <img className='h-[300px]' src={post.imageUrl} loading='lazy' alt='imag' ></img>
      <div className='font-mono text-xl'>
       {
        post.color === 2 ?  (<div className='absolute text-white bottom-4 px-4 text-left text-wrap bg-rose-300 h-20 w-full'>
            <p className='text-black font-bold text-center mt-6 '>{post.title}</p>
        </div>) :
        post.color === 3 ? ( <div className='absolute text-white bottom-4 px-4 text-left text-wrap bg-blue-300 h-20 w-full'>
            <p className='text-black font-bold text-center mt-6'>{post.title}</p>
        </div>) :
        post.color === 1 ? ( <div className='absolute text-white bottom-4 px-4 text-left text-wrap bg-lime-300 h-20 w-full'>
            <p className='text-black font-bold text-center mt-6'>{post.title}</p>
        </div>) :
        post.color === 4 ? ( <div className='absolute text-white bottom-4 px-4 text-left text-wrap bg-gray-300 h-20 w-full'>
            <p className='text-black font-bold text-center mt-6'>{post.title}</p>
        </div>) :
        ( <div className='absolute text-white bottom-4 px-4 text-left text-wrap bg-green-300 h-20 w-full'>
            <p className='text-black font-bold text-center mt-6'>{post.title}</p>
        </div>)
       }
     </div>
        </NavLink>
    </div>
  )
}

export default FoodChartCard