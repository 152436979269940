import React, { useEffect, useState } from 'react'
import "../YoutubeEmbed.css";
import YoutubeEmbed from "../YoutubeEmbed";
import '../google-poppins-font.css';
import data from '../../data/recipes.json';
import { Link, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import InfoVideoCard from '../InfoVideoCard';

function InfoPage({posts}) {

     function fetchBlogsData(){
        try{
          getData();
        }
        catch(err){
          console.log(err);
        }
      }

      const foodVideosCollectionRef = collection(db, "InfoBlogs");

    const getData =  async() => {
        const data = await getDocs(foodVideosCollectionRef);
        const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
       
        //setPosts(filteredData);
      }
    
      useEffect(() => {
        //fetchBlogsData();
        window.scroll(0,0);
      }, []);

  return (
    <div>
    <p className='p-2'><strong><Link to="/">Home</Link> </strong> / <strong><Link to="/baby-food-info">Parenting Blog</Link> </strong>/ 7 Days 7 Breakfast Recipes  </p>
    <div className='w-full sm:w-10/12 mx-auto googlefontpoppins px-2'>
    
       {
         posts.length > 0 && posts.map((post, index) => (
          <div className='w-full mx-auto googlefontpoppins py-3 '>
            <div className='mt-12 text-3xl sm:text-4xl poppins-regular text-blue-600 text-center sm:text-left'>{index+ 1}. {post.title}</div>


            <div className='flex justify-between max-md:hidden'>
              <div className="w-full mx-auto md:text-left p-4 text-richblack-700 googlefontpoppins text-[17px] mt-6 leading-9">        
                          <span dangerouslySetInnerHTML={{__html: post.description}}  />  
              </div>
              <div>
                  <InfoVideoCard key={post.title} url={post.instaUrl} imageUrl={post.imageUrl} title={post.title}/>
              </div>
            </div>

            
            <div className='flex flex-col justify-center md:hidden'>
              <div className="w-full mx-auto md:text-left p-4 text-richblack-700 googlefontpoppins text-[17px]  mt-6 leading-9">        
                          <span dangerouslySetInnerHTML={{__html: post.description}}  />  
              </div>
              <div>
                  <InfoVideoCard key={post.title} url={post.instaUrl} imageUrl={post.imageUrl} title={post.title}/>
              </div>
            </div>
            <div className='border-b-2 border-gray-200 w-full mt-4'></div>
        </div>
         ))
       }
        

        
    </div>
    {/* <div className='w-full sm:w-10/12 mx-auto p-4'>
    {
       post.youtubeLink ? (<div><div className='text-3xl font-semibold googlefontpoppins'>Watch this video to see how to prepare:</div>
      <div className='mt-8'>
      <YoutubeEmbed embedId={post.youtubeLink} /></div> </div>) 
      : (<></>)
    }
    </div> */}
    </div>
  )
}

export default InfoPage